import { Menu, Layout, theme, Modal } from "antd";
import React, { useState } from "react";
import {
  BarsOutlined,
  PlusCircleOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const requestList = {
  name: "Request List",
  icon: BarsOutlined,
  link: "request_list",
};
const createRequest = {
  name: "Create A Request",
  icon: PlusCircleOutlined,
  link: "request_creation",
};
const message = {
  name: "Message",
  icon: CommentOutlined,
  link: "request_message",
};

export default function RequestSideMenu(props) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(props.select);

  const navigators = [requestList, createRequest, message].map(
    (item, index) => {
      const key = String(index + 1);
      const link = "/" + item.link;

      return {
        key: `${key}`,
        icon: React.createElement(item.icon),
        label: (
          <div
            onClick={(e) => {
              if (props.warning === true) {
                Modal.confirm({
                  title: "Leave this page?",
                  content:
                    "You will lose your unsaved changes if you leave this page. Are you sure you want to leave?",
                  onOk: () => {
                    e.preventDefault();
                    navigate(link);
                    setSelectedKey(key);
                  },
                });
              } else {
                navigate(link);
                setSelectedKey(key);
              }
            }}
          >
            {item.name}
          </div>
        ),
      };
    }
  );
  return (
    <Layout>
      <Layout.Sider
        width={220}
        style={{
          background: colorBgContainer,
          paddingTop: 24,
        }}
      >
        <Menu
          theme="light"
          items={navigators}
          mode="inline"
          defaultSelectedKeys={[props.select]}
          selectedKeys={[selectedKey]}
          style={{
            height: "100%",
            borderRight: 0,
            borderRadius: borderRadiusLG,
          }}
        />
      </Layout.Sider>
      {props.children}
    </Layout>
  );
}
