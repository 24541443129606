import RequestSideMenu from "../Component/RequestComponent/RequestSideMenu";
import MainHeader from "../Component/Header";
import Footer from "../Component/Footer";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  theme,
  Input,
  Popover,
  Select,
  Tag,
  Button,
  DatePicker,
  Form,
  Table,
} from "antd";
import { ControlOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
const { Search } = Input;
const { RangePicker } = DatePicker;

const selectStatus = [
  {
    value: "New Updated",
  },
  {
    value: "On Progress",
  },
  {
    value: "Finished",
  },
  {
    value: "Pending",
  },
  {
    value: "Paid",
  },
];
const colorMap = (value) => {
  switch (value) {
    case "New Updated":
      return "green";
    case "On Progress":
      return "cyan";
    case "Finished":
      return "red";
    case "Pending":
      return "orange";
    case "Paid":
      return "blue";
    default:
      return "empty";
  }
};

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={colorMap(value)}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginInlineEnd: 4,
      }}
    >
      {label}
    </Tag>
  );
};

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_, { status }) => (
      <>
        {status.map((state, index) => {
          let color = "none";

          if (state === "New Updated") {
            color = "green";
          } else if (state === "On Progress") {
            color = "cyan";
          } else if (state === "Finished") {
            color = "red";
          } else if (state === "Pending") {
            color = "orange";
          } else if (state === "Paid") {
            color = "blue";
          }
          return (
            <p key={index}>
              <Tag color={color}>{state}</Tag>
            </p>
          );
        })}
      </>
    ),
  },
  {
    title: "Due date",
    dataIndex: "deadline",
    key: "deadline",
    render: (text) => (text === null ? <p>TBD</p> : <p>{text}</p>),
    sorter: (a, b) => {
      const dateA = dayjs(a.deadline, "DD MM YYYY");
      const dateB = dayjs(b.deadline, "DD MM YYYY");

      if (!dateA.isValid()) {
        return -1;
      }
      if (!dateB.isValid()) {
        return 1;
      }

      return dateA.unix() - dateB.unix();
    },
  },

  {
    title: "Updated date",
    dataIndex: "update",
    key: "update",
    render: (text) => (text === null ? <p>TBD</p> : <p>{text}</p>),
    defaultSortOrder: "descend",
    sorter: (a, b) => {
      const dateA = dayjs(a.update, "DD MM YYYY");
      const dateB = dayjs(b.update, "DD MM YYYY");

      if (!dateA.isValid()) {
        return 1;
      }
      if (!dateB.isValid()) {
        return -1;
      }

      return dateA.unix() - dateB.unix();
    },
  },
  {
    title: "Assign To",
    key: "assigns",
    dataIndex: "assigns",
    render: (_, { assigns }) => (
      <>
        {assigns === null ? (
          <p>TBD</p>
        ) : (
          assigns.map((assign, index) => {
            return <p key={index}>{assign}</p>;
          })
        )}
      </>
    ),
  },
];
export default function RequestList() {
  const [height, setHeight] = useState(window.innerHeight);
  const [requests, setRequests] = useState(data);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  //This function is used to handle search request, info?.source will return 'input'
  const onSearch = (value, _e, info) => {
    setRequests(
      data.filter((request) => {
        return request.title.toLowerCase().includes(value.toLowerCase());
      })
    );
  };
  const onFilterFinish = (values) => {
    let searchResult = data;
    if (values.status && values.status.length > 0) {
      searchResult = searchResult.filter((request) =>
        values.status.some((status) => request.status.includes(status))
      );
    }
    if (values.deadline) {
      searchResult = searchResult.filter((request) => {
        if (request.deadline) {
          return dayjs(request.deadline, "DD MM YYYY").isBetween(
            values.deadline[0],
            values.deadline[1]
          );
        } else {
          return false;
        }
      });
    }
    if (values.Updated) {
      searchResult = searchResult.filter((request) => {
        if (request.update) {
          return dayjs(request.update, "DD MM YYYY").isBetween(
            values.Updated[0],
            values.Updated[1]
          );
        } else {
          return false;
        }
      });
    }
    setRequests(searchResult);
  };
  const onFilterFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const filter = (
    <Form
      name="basic"
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 350,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFilterFinish}
      onFinishFailed={onFilterFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Status" name="status">
        <Select
          placeholder="Select status"
          mode="multiple"
          tagRender={tagRender}
          options={selectStatus}
        />
      </Form.Item>
      <Form.Item label="Deadline" name="deadline">
        <RangePicker />
      </Form.Item>
      <Form.Item label="Updated" name="Updated">
        <RangePicker />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button htmlType="submit">Apply Filiter</Button>
      </Form.Item>
    </Form>
  );
  return (
    <MainHeader select="Request">
      <RequestSideMenu select="1">
        <Layout.Content
          style={{
            padding: 24,
            marginLeft: 2,
            minHeight: 280,
            background: colorBgContainer,
            height: height - 135,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Search
              placeholder="search request by title"
              onSearch={onSearch}
              style={{ width: 400 }}
            />
            <div style={{ float: "right" }}>
              <Popover
                content={filter}
                placement="bottomLeft"
                trigger="click"
                arrow={false}
              >
                <ControlOutlined style={{ fontSize: 30 }} />
              </Popover>
            </div>
          </div>
          <Table
            style={{ paddingTop: 24 }}
            columns={columns}
            dataSource={requests}
            pagination={false}
            virtual
            scroll={{ y: height - 280 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate("/request_details");
                },
              };
            }}
          />
        </Layout.Content>
      </RequestSideMenu>
      <Footer />
    </MainHeader>
  );
}

const data = [
  {
    key: "1",
    title: "Project 1",
    status: ["New Updated"],
    deadline: "15/01/2024",
    update: "22/03/2024",
    assigns: ["Technician 1"],
  },
  {
    key: "2",
    title: "Project 2",
    status: ["On Progress"],
    deadline: "07/05/2024",
    update: "30/07/2024",
    assigns: ["Technician 2"],
  },
  {
    key: "3",
    title: "Project 3",
    status: ["Finished"],
    deadline: "18/10/2024",
    update: "05/12/2024",
    assigns: ["Technician 3"],
  },
  {
    key: "4",
    title: "Project 4",
    status: ["Pending"],
    deadline: "17/01/2024",
    update: null,
    assigns: null,
  },
  {
    key: "5",
    title: "Project 5",
    status: ["Paid"],
    deadline: "22/03/2024",
    update: null,
    assigns: ["Technician 1"],
  },
];
