import { Button, Space } from "antd";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createFromIconfontCN } from "@ant-design/icons";
import SignIn from "./SignIn";

const IconFont = createFromIconfontCN({
  scriptUrl:
    "https://at.alicdn.com/t/c/font_4531892_5bbigk4h78u.js?spm=a313x.manage_type_myprojects.i1.10.45003a81ausWcV&file=font_4531892_5bbigk4h78u.js",
});

export default function HomeHeader() {
  const [ismobile, setIsMobile] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? 1 : 0);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that ismobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "6vh",
        width: "100%",
      }}
    >
      <img
        src={ismobile ? "/titleLogoLight.png" : "/RfSonsLogoLight.png"}
        alt=""
        style={{
          height: "60%",
          paddingLeft: "10%",
        }}
        onClick={() => {
          window.location = "/";
        }}
      ></img>
      <Space
        direction="horizontal"
        style={{
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <StyledButton
          ismobile={ismobile}
          type="text"
          icon={ismobile ? <IconFont type="icon-portfolio" /> : ""}
          onClick={(e) => {
            navigate("/#portfolio");
            const portfolioElement = document.getElementById("portfolio");
            if (portfolioElement) {
              portfolioElement.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          {ismobile ? " " : "Portfoilo"}
        </StyledButton>
        <StyledButton
          ismobile={ismobile}
          type="text"
          icon={ismobile ? <IconFont type="icon-jianmo" /> : ""}
          onClick={(e) => {
            navigate("/#services");
            const servicesElement = document.getElementById("services");
            if (servicesElement) {
              servicesElement.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          {ismobile ? " " : "Services"}
        </StyledButton>
      </Space>

      <SignIn
        ismobile={ismobile}
        style={{
          height: "100%",
          width: "8%",
          marginRight: "12%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      />
    </div>
  );
}

const StyledButton = styled(Button)`
  font-size: calc(
    100vh * ${(props) => (props.ismobile ? 20 : 18)} /
      ${(props) => (props.ismobile ? 720 : 1080)}
  );
  margin-right: 10%;
  background-color: transparent;
  color: white;
  height: 100%;
  &&:hover {
    color: #18dcff !important;
  }
`;
