import { Row, Col } from "antd";

import styled, { keyframes, css } from "styled-components";
import { useState, useEffect, useRef } from "react";
import TransparentCard from "./TransparentCard";

export default function ShowCaseCardRow(options) {
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible && entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isVisible]);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? true : false);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that isMobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row
      justify="center"
      style={{
        paddingTop: "6vh",
        paddingBottom: "5vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      {options.children.map((option, index) => {
        const firstItem = index === 0;
        return (
          <Col
            span={isMobile ? 16 : 8}
            style={{
              paddingLeft: "2%",
              paddingBottom: isMobile ? "3vh" : "6vh",
              paddingRight: "2%",
            }}
            key={index + option.title}
          >
            <StyledFadeAnimation
              isVisible={isVisible}
              ref={firstItem ? ref : null}
              delay={0.25 * index}
            >
              <TransparentCard option={option} zoom={1} />
            </StyledFadeAnimation>
          </Col>
        );
      })}
    </Row>
  );
}

const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const StyledFadeAnimation = styled.div`
  opacity: 0;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fadeInFromBottom} 1s ease-out forwards;
      animation-delay: ${(props) => props.delay || 0}s;
    `}
`;
