import RequestSideMenu from "../Component/RequestComponent/RequestSideMenu";
import MainHeader from "../Component/Header";
import {
  Layout,
  theme,
  Upload,
  Button,
  Image,
  List,
  Space,
  Radio,
  Flex,
  Input,
} from "antd";

import {
  ArrowLeftOutlined,
  EditOutlined,
  DownloadOutlined,
  SendOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VirtualList from "rc-virtual-list";

export default function RequestDetails() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [inputMessage, setInputMessage] = useState("");

  let navigate = useNavigate();
  const [commentSelection, setCommentSelection] = useState("active");
  const onCommentMenuClick = (e) => {
    console.log(e.target.value);
    setCommentSelection(e.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer, colorBorder, colorBgLayout },
  } = theme.useToken();
  //This function is used to handle search request, info?.source will return 'input'

  return (
    <MainHeader select="Request">
      <RequestSideMenu select="1">
        <Layout.Content
          style={{
            marginLeft: 2,
            minHeight: 280,
            background: colorBgContainer,
            height: height - 66,
            overflowY: "hidden",
          }}
        >
          <div style={{ width: "100%", height: 150, padding: 24 }}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined style={{ fontSize: 30 }} />}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Space
              direction="vertical"
              style={{
                paddingLeft: 0.05 * (width - 220),
              }}
            >
              <div>
                <h1>
                  Request Name
                  <Button
                    type="text"
                    style={{ marginLeft: 48 }}
                    icon={<EditOutlined style={{ fontSize: 24 }} />}
                    onClick={() => {
                      navigate("/request_creation", {
                        state: { requestUid: "123456" },
                      });
                    }}
                  />
                </h1>
              </div>
              <strong>Request information: 123546</strong>
              <strong>Request information 2: 78945</strong>
            </Space>
            <StyledDiv>
              <StyledItem>
                <h2>State:</h2>
                <h2 style={{ color: "orange" }}>Pending</h2>
              </StyledItem>

              <StyledItem>
                <span>Estimate price:</span>
                <span>$40</span>
              </StyledItem>
              <StyledItem>
                <span>Actual price:</span>
                <span>$80</span>
              </StyledItem>
            </StyledDiv>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 24,
              height: height - 180 - 66,
              borderWidth: 0,
              borderTopWidth: 2,
              borderStyle: "solid",
              borderColor: colorBorder,
            }}
          >
            <div style={{ width: "20%" }}>
              <Button
                // onClick={showAddModal}
                style={{
                  width: "100%",
                  height: 120,
                  fontSize: 20,
                  borderWidth: 0,
                  borderRadius: 0,
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 1,
                  borderLeftWidth: 0,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    float: "left",
                    height: "100%",
                    alignContent: "center",
                    padding: "5%",
                    width: "80%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Download All
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "5%",
                    height: "100%",
                    alignContent: "center",
                    padding: "5%",
                  }}
                >
                  <DownloadOutlined />
                </div>
              </Button>

              <List>
                <div
                  style={{
                    borderWidth: 0,
                    borderStyle: "solid",
                    borderColor: colorBorder,
                  }}
                >
                  <VirtualList
                    data={resultFileList}
                    height={height - 380}
                    itemKey="title"
                    style={{
                      borderWidth: "0px",
                      borderTopWidth: 0,
                    }}
                  >
                    {(item, index) => {
                      return (
                        <List.Item
                          key={item.uid}
                          style={{
                            padding: 0,
                            height: 40,
                            overflowX: "hidden",
                            borderWidth: 0,
                            marginTop: index === 0 ? 0 : 8,
                          }}
                        >
                          <Button
                            onClick={() => {
                              console.log(item.name);
                              window.location.href = item.url;
                            }}
                            type="text"
                            style={{
                              width: "100%",
                              height: "100%",
                              paddingLeft: 0,
                              paddingRight: 0,
                              paddingTop: 0,
                              paddingBottom: 0,
                              borderRadius: 0,
                              textAlign: "left",
                              borderWidth: 0,
                              borderTopWidth: index === 0 ? 0 : 1,
                              borderColor: colorBorder,
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                fontSize: 14,
                                alignContent: "center",
                                paddingLeft: "10%",
                                paddingRight: 36,
                                paddingTop: 8,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.name}
                              <DownloadOutlined
                                style={{
                                  position: "absolute",
                                  right: 15,
                                  fontSize: 20,
                                }}
                              />
                            </div>
                          </Button>
                        </List.Item>
                      );
                    }}
                  </VirtualList>
                </div>
              </List>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "80%",
                borderWidth: 2,
                borderLeftColor: colorBorder,
                borderLeftStyle: "solid",
              }}
            >
              <List
                style={{
                  height: "100%",
                }}
              >
                <div>
                  <div
                    style={{
                      height: 120,
                      borderBottomWidth: 1,
                      borderBottomColor: colorBorder,
                      borderBottomStyle: "solid",
                    }}
                  >
                    <div
                      style={{
                        height: 80,
                        boxShadow: "none", // Remove the shadow
                        backgroundColor: "transparent",
                        fontWeight: "bold",
                        fontSize: 20,
                        alignContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Comments
                    </div>
                    <StyledRadioGroup
                      value={commentSelection}
                      onChange={onCommentMenuClick}
                      style={{
                        width: "100%",

                        zIndex: 2,
                        position: "relative",
                        marginBottom: -8,
                        fontWeight: "bold",
                        fontSize: 14,
                        borderWidth: 0,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Radio.Button
                        value="active"
                        style={{
                          width: "50%",
                          height: "100%",
                          alignContent: "center",
                          borderLeftWidth: 0,
                          borderTopWidth: 0,
                          borderBottomWidth: 0,
                          borderRadius: 0,
                          borderRightWidth: 0,
                          textAlign: "center",
                          backgroundColor: "transparent",
                        }}
                      >
                        Active
                      </Radio.Button>
                      <Radio.Button
                        value="resolved"
                        style={{
                          width: "50%",
                          height: "100%",
                          alignContent: "center",
                          borderBottomWidth: 0,
                          borderRightWidth: 0,
                          borderTopWidth: 0,
                          borderRadius: 0,
                          borderLeftWidth: 0,
                          borderLeftColor: "transparent",
                          borderLeftStyle: "none",
                          textAlign: "center",
                          backgroundColor: "transparent",
                        }}
                      >
                        Resolved
                      </Radio.Button>
                    </StyledRadioGroup>
                  </div>
                  <VirtualList
                    data={commentList}
                    height={height - 498}
                    itemKey="uid"
                    style={{
                      borderTopWidth: 0,
                      borderBottomWidth: 1,
                      borderColor: colorBorder,
                      borderStyle: "solid",
                      borderLeftWidth: 0,
                      borderRightWidth: 0,

                      zIndex: 1,
                    }}
                  >
                    {(item, index) => {
                      let borderTop = false;
                      if (index === 0) {
                        borderTop = true;
                      }
                      let imageDisplay = "none";
                      if (item.picture !== "") {
                        imageDisplay = "";
                      }
                      return (
                        <List.Item
                          key={item.uid}
                          style={{
                            fontSize: 16,
                            padding: 16,
                            borderTopWidth: borderTop ? 0 : 1,
                            borderStyle: "solid",
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            marginTop: 8,
                            borderColor: colorBorder,
                            alignContent: "center",
                          }}
                        >
                          <Space direction="vertical">
                            <div>{item.content}</div>
                            <Image
                              width={150}
                              src={item.picture}
                              style={{ display: imageDisplay }}
                            ></Image>
                            <div style={{ fontSize: 12 }}>
                              Published Date: {item.publishDate}
                            </div>
                          </Space>
                        </List.Item>
                      );
                    }}
                  </VirtualList>
                  <div
                    style={{
                      height: 118,
                      alignContent: "center",
                      paddingLeft: 24,
                      borderLeftRadius: 10,
                      borderRightRadius: 10,
                      backgroundColor: colorBgLayout,
                    }}
                  >
                    <Flex direction="horizontal" style={{ width: "100%" }}>
                      <Input.TextArea
                        placeholder="Type your message"
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        style={{ width: "95%" }}
                        onChange={(e) => setInputMessage(e.target.value)}
                      />
                      <Space
                        direction="vertical"
                        size={"large"}
                        style={{ paddingLeft: 16, paddingRight: 16 }}
                      >
                        <Upload {...attachmentProps}>
                          <LinkOutlined
                            style={{
                              fontSize: 16,
                              paddingTop: 16,
                            }}
                            onClick={(e) => {
                              console.log("LaoDa");
                            }}
                          />
                        </Upload>
                        <SendOutlined
                          style={{ fontSize: 16, paddingBottom: 8 }}
                          onClick={(e) => {
                            console.log(inputMessage);
                          }}
                        />
                      </Space>
                    </Flex>
                  </div>
                </div>
              </List>
            </div>
          </div>
        </Layout.Content>
      </RequestSideMenu>
    </MainHeader>
  );
}

const StyledDiv = styled.div`
  height: 150px;
  width: 200px;
  float: right;
  margin-bottom: 18px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ddd;
  margin-right: 5vw;
  border-radius: 5%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
`;

const StyledRadioGroup = styled(Radio.Group)`
  height: 40 !important;

  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none; // This line removes the separator
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  font-family: "Arial", sans-serif;
  margin-top: -5;
  span:last-child {
    font-weight: bold;
  }
`;

const resultFileList = [
  {
    uid: "-1",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-2",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-3",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-4",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-5",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-6",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-7",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-8",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-9",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-10",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-11",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-12",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-13",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
];
const commentList = [
  {
    uid: 1,
    content: "In RF Sons Engineering page 2, there is a mistake about xxxx.",
    picture:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
    publishDate: "2021-09-01",
    state: "active",
    resolvedDate: "-",
  },
  {
    uid: 2,
    content: "In xxx project, the site top view should be in this form.",
    picture:
      "https://upload.wikimedia.org/wikipedia/commons/9/9b/Lochaber_Centre_Site_Plan.jpg",
    publishDate: "2021-09-02",
    state: "active",
    resolvedDate: "-",
  },
  {
    uid: 3,
    content: "Rebuild xxx model, seems the file has been damaged.",
    picture: "",
    publishDate: "2021-09-03",
    state: "active",
    resolvedDate: "-",
  },
  {
    uid: 4,
    content: "In RF Sons Engineering page 2, there is a mistake about xxxx.",
    picture:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
    publishDate: "2021-09-04",
    state: "active",
    resolvedDate: "-",
  },
];
const attachmentProps = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  accept: ".jpg,.jpeg,.png",
  maxCount: 1,
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      console.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.error(`${info.file.name} file upload failed.`);
    }
  },
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    style: {
      width: 250,
      fontSize: 12,
    },
    strokeWidth: 3,
    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
  },
};
