import { Menu, Layout, theme, Button, Space, Modal } from "antd";
import { useThemeContext } from "../App";
import { getCurrentUser } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const navigators = ["Request", "Market", "Course"].map((key, index) => ({
  key,
  label: `${key}`,
  disabled: index === 0 ? false : true,
}));

export default function MainHeader(props) {
  const { state } = useThemeContext();
  const [loginModal, setLoginModal] = useState(false);
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    async function loginVerification() {
      try {
        const { userId, signInDetails } = await getCurrentUser();

        console.log(`The userId: ${userId}`);
        console.log(signInDetails.loginId);
      } catch (err) {
        console.log(err);
        setLoginModal(true);
      }
    }
    loginVerification();
  }, [navigate, setLoginModal]);

  return (
    // <HeaderSelectionContext.Provider value={setCurrentPage}>
    <Layout style={{ height: "100%", overflow: "auto" }}>
      <Layout.Header
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "2px",
          background: colorBgContainer,
        }}
      >
        <img
          src={
            state.name === "light" ? "/RfSonsLogo.png" : "/RfSonsLogoLight.png"
          }
          alt="Logo"
          onClick={() => {
            window.location = "/home";
          }}
          style={{ width: "128px", height: "40px", marginRight: "32px" }}
        />
        <Menu
          theme="light"
          items={navigators}
          defaultSelectedKeys={[props.select]}
          mode="horizontal"
          style={{ borderBottom: "0px" }}
        />
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "right" }}
        >
          <Button type="text">Hello! User Name</Button>
        </Space>
      </Layout.Header>
      {props.children}
      <Modal
        title="Sign In Required"
        centered
        style={{ textAlign: "center" }}
        open={loginModal}
        onCancel={() => {
          Modal.destroyAll();
        }}
        footer={[
          <Button key="submit" type="primary" onClick={() => navigate("/")}>
            OK
          </Button>,
        ]}
      >
        <p>Please sign in your account</p>
      </Modal>
    </Layout>
    // {/* </HeaderSelectionContext.Provider> */}
  );
}
// export const useHeaderSelectionContext = () =>{
//   return useContext(HeaderSelectionContext);
// }
