import { Row, Col, Card } from "antd";
import styled, { keyframes, css } from "styled-components";
import { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

const { Meta } = Card;

export default function ShowCaseCardRow(options) {
  const [isMobile, setIsMobile] = useState(false);
  const [clickedCard, setClickedCard] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible && entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isVisible]);

  useEffect(() => {
    setClickedCard([]);
    setIsVisible(false);
  }, [options]);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerHeight > window.innerWidth ? true : false);
    window.addEventListener("resize", handleResize);
    // Call handleResize right away so that isMobile is set correctly initially
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row
      justify="center"
      style={{
        paddingLeft: isMobile ? 0 : "5vw",
        paddingRight: isMobile ? 0 : "5vw",
      }}
    >
      {options.children.map((option, index) => {
        let imgDisplay = true;
        if (option.img.includes("mp4")) {
          imgDisplay = false;
        }
        const firstItem = index === 0;
        return (
          <Col
            span={isMobile ? 20 : 8}
            style={{
              paddingLeft: isMobile ? 0 : "2%",
              paddingBottom: isMobile ? "5vh" : "10vh",
              paddingRight: isMobile ? 0 : "2%",
            }}
            key={option.title + index}
          >
            {
              <AnimatedCard
                cover={
                  imgDisplay ? (
                    <img
                      alt="showcase"
                      src={option.img}
                      style={{
                        borderRadius: 10,
                      }}
                    />
                  ) : (
                    <ReactPlayer
                      url={option.img}
                      playing
                      playsinline
                      muted
                      controls
                      height="auto"
                      width="100%"
                      loop
                    />
                  )
                }
                isVisible={isVisible}
                ref={firstItem ? ref : null}
                delay={0.25 * index}
                onClick={() => {
                  if (clickedCard.includes(index)) {
                    setClickedCard(
                      clickedCard.filter((card) => card !== index)
                    );
                  } else {
                    setClickedCard([...clickedCard, index]);
                  }
                }}
                clicked={clickedCard.includes(index)}
              >
                <Meta
                  title={option.title}
                  description={
                    <Description in={clickedCard.includes(index)}>
                      {option.content}
                    </Description>
                  }
                />
              </AnimatedCard>
            }
          </Col>
        );
      })}
    </Row>
  );
}
const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const AnimatedCard = styled(Card)`
  opacity: 0;
  position: relative;
  border-radius: 3%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    border 0.3s ease-in-out;
  background: linear-gradient(to right bottom, #445e7c, #050f27);
  border-color: transparent;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${fadeInFromBottom} 1s ease-out forwards;
      animation-delay: ${(props) => props.delay || 0}s;
    `}

  &:hover {
    transform: scale(1.1);
    z-index: 2;
    box-shadow: 0 0 10px 5px #00dbf8;
  }
  .ant-card-meta-title {
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ant-card-meta-description {
    height: ${(props) => (props.clicked ? "10vh" : "0")};
    transition: height 0.3s ease-in-out;
    overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
  }
  .ant-card-head {
    height: 0;
    display: none;
  }
`;

const Description = styled.div`
  color: white;
  display: ${(props) => (props.in ? "block" : "none")};
  height: 0;
  animation-fill-mode: forwards;
`;
